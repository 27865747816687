import { motion } from "framer-motion"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, {
  MouseEvent,
  MouseEvent as ReactMouseEvent,
  useRef,
  useState,
} from "react"
import { Paint } from "../../types"
import {
  available,
  container,
  dimension,
  pictureContainer,
  title,
} from "./oeuvre.css"

type OeuvreProps = {
  data: Paint
}
type MousePosition = {
  x: number
  y: number
  width: number
  height: number
  centerX: number
  centerY: number
}

function getRelativeCoordinates(event: any, referenceElement: any) {
  const position = {
    x: event.pageX,
    y: event.pageY,
  }

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight,
  }

  let reference = referenceElement.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY:
      (position.y - offset.top - offset.height / 2) / (offset.height / 2),
  }
}

const Oeuvre = ({ data }: OeuvreProps) => {
  const pictureRef = useRef<any>(null)
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    centerX: 0,
    centerY: 0,
  })

  const handleMouseMove = (
    e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setMousePosition(getRelativeCoordinates(e, pictureRef.current))
  }
  const image = getImage(data.image?.gatsbyImageData)

  if (!image) return null
  return (
    <div className={container}>
      <h3 className={title}>{data.titre}</h3>
      <Link to={`/peinture/${data.slug}`} className={pictureContainer}>
        <motion.div
          ref={pictureRef}
          onMouseMove={(e: any) => handleMouseMove(e)}
          whileHover={{
            rotateX: mousePosition.centerX * 20,
            rotateY: mousePosition.centerY * 20,
          }}
        >
          <GatsbyImage image={image} alt={data.image.title} />
        </motion.div>
      </Link>
      <p className={available}>{data.status ? "Disponible" : "Vendu"}</p>
      <p className={dimension}>{data.dimension}</p>
    </div>
  )
}

export default Oeuvre
