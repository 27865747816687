import { motion } from "framer-motion"
import { graphql, PageProps } from "gatsby"
import React from "react"
import Layout from "../component/layout/layout"
import Oeuvre from "../component/oeuvre/oeuvre"
import { pageContainer, title } from "../styles.css"
import { oeuvresContainer } from "../styles/galerie.css"
import { Paint } from "../types"

type Props = {}

type DataType = {
  paintings: {
    edges: {
      node: Paint
    }[]
  }
}

const Galerie = ({ data: { paintings } }: PageProps<DataType>) => {
  const oeuvresAnimate = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.5,
      },
    },
  }

  const oeuvre = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }
  return (
    <Layout>
      <div className={pageContainer}>
        <h3 className={title}>Galerie</h3>
        <motion.div
          className={oeuvresContainer}
          variants={oeuvresAnimate}
          initial="hidden"
          animate="show"
        >
          {paintings.edges.map(
            ({ node }) =>
              node && (
                <motion.div variants={oeuvre} key={node.id}>
                  <Oeuvre data={node} key={node.id} />
                </motion.div>
              )
          )}
        </motion.div>
      </div>
    </Layout>
  )
}

export default Galerie

export const query = graphql`
  {
    paintings: allContentfulTableau {
      edges {
        node {
          titre
          id
          slug
          prix
          status
          dimension
          image {
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`
